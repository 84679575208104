import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Menu from '../components/menu';
import Footer from '../components/footer';

const LegalPage = ({ location }) => {
    const [eid, setEid] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    if (typeof window !== 'undefined') {
        window.location = 'https://nats.bcash4you.com/external.php?page=signup';
    }

    return null;
    return (
        <Layout>
            <Menu location={location} />
            <h1 className="huge">Signup</h1>
            <p className="padding-bottom-0">
                If you have not yet signed up under a{' '}
                <a
                    href="http://www.ccbill.com"
                    className="biglink"
                    title="CCBill"
                    shape="rect"
                >
                    CCBill
                </a>{' '}
                affiliate program you can make the signup here:
            </p>
            <div className="spacer spacer-40">&nbsp;</div>

            <form action="https://admin.ccbill.com/signup.cgi" method="POST">
                <div className="forms">
                    <div className="forms-fix">
                        <input type="hidden" name="CA" value="930360-0000" />
                        <input type="hidden" name="GR" value="" />
                        <input type="hidden" name="page_background" value="" />
                        <input
                            type="hidden"
                            name="page_bgcolor"
                            value="#FFFFFF"
                        />
                        <input type="hidden" name="page_text" value="#000000" />
                        <input type="hidden" name="page_link" value="blue" />
                        <input type="hidden" name="page_vlink" value="purple" />
                        <input type="hidden" name="page_alink" value="blue" />
                        <input
                            type="hidden"
                            name="table_left"
                            value="#CCCCCC"
                        />
                        <input
                            type="hidden"
                            name="table_right"
                            value="#EEEEEE"
                        />
                        <input
                            type="hidden"
                            name="table_text"
                            value="#000000"
                        />
                        <input
                            type="hidden"
                            name="star_color"
                            value="#CC0000"
                        />
                        <div className="button-container">
                            <button className="button" type="submit">
                                <span>
                                    Create{' '}
                                    <span className="hide-for-tablet">
                                        new{' '}
                                    </span>
                                    account
                                </span>
                            </button>
                        </div>
                        <input type="hidden" name="second" value="1" />
                    </div>
                </div>
            </form>

            <div className="spacer spacer-40">&nbsp;</div>
            <p className="mini padding-bottom-0">
                The webmaster referral program is provided by our authorized
                sales agent{' '}
                <a
                    href="http://www.ccbill.com"
                    className="underline"
                    title="CCBill"
                >
                    CCBill
                </a>
                . You will get an affiliate ID to&nbsp;login to BCASH4YOU. By
                clicking 'Create new account' button, you agree to&nbsp;our{' '}
                <Link
                    to="/legal#terms"
                    className="underline"
                    title="Terms and Conditions"
                >
                    Terms
                </Link>{' '}
                and{' '}
                <Link
                    to="/legal#privacy"
                    title="Privacy Policy"
                    className="underline"
                >
                    Privacy
                </Link>{' '}
                and to&nbsp;receive our emails&nbsp;&amp;&nbsp;updates.
            </p>
            <div className="spacer line spacer-80">&nbsp;</div>

            <div className="spacer spacer-80">&nbsp;</div>
            <p className="padding-bottom-0">
                If you have already a CCBill affiliate account, please use this
                form to join our affiliate program:
            </p>
            <div className="spacer spacer-40">&nbsp;</div>

            <form action="https://admin.ccbill.com/signup.cgi" method="POST">
                <div className="forms">
                    <div className="forms-fix max-width-520">
                        <input type="hidden" name="CA" value="930360-0000" />
                        <input type="hidden" name="GR" value="" />
                        <input type="hidden" name="page_background" value="" />
                        <input
                            type="hidden"
                            name="page_bgcolor"
                            value="#FFFFFF"
                        />
                        <input type="hidden" name="page_text" value="#000000" />
                        <input type="hidden" name="page_link" value="blue" />
                        <input type="hidden" name="page_vlink" value="purple" />
                        <input type="hidden" name="page_alink" value="blue" />
                        <input
                            type="hidden"
                            name="table_left"
                            value="#CCCCCC"
                        />
                        <input
                            type="hidden"
                            name="table_right"
                            value="#EEEEEE"
                        />
                        <input
                            type="hidden"
                            name="table_text"
                            value="#000000"
                        />
                        <input
                            type="hidden"
                            name="star_color"
                            value="#CC0000"
                        />
                        <div className="compact">
                            <div className="form-group first">
                                <input
                                    type="text"
                                    name="EID"
                                    id="current-id"
                                    value={eid}
                                    onChange={e => setEid(e.target.value)}
                                    className={
                                        eid.trim() !== '' ? 'has-value' : ''
                                    }
                                />
                                <label
                                    className="control-label"
                                    htmlFor="current-id"
                                >
                                    Current ID
                                </label>
                            </div>
                            <div className="form-group first">
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    className={
                                        username.trim() !== ''
                                            ? 'has-value'
                                            : ''
                                    }
                                />
                                <label
                                    className="control-label"
                                    htmlFor="username"
                                >
                                    Username
                                </label>
                            </div>
                            <div className="form-group last">
                                <input
                                    type="password"
                                    name="password"
                                    id="password-field"
                                    maxlength="16"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    className={
                                        password.trim() !== ''
                                            ? 'has-value'
                                            : ''
                                    }
                                />
                                <label
                                    className="control-label"
                                    htmlFor="password"
                                >
                                    Password
                                </label>
                            </div>
                        </div>
                        <div className="spacer spacer-16">&nbsp;</div>
                        <div className="button-container">
                            <button className="button full" type="submit">
                                <span>Retrieve data</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>

            <div className="spacer spacer-40">&nbsp;</div>
            <p className="mini padding-bottom-0">
                The webmaster referral program is provided by our authorized
                sales agent{' '}
                <a
                    href="http://www.ccbill.com"
                    className="underline"
                    title="CCBill"
                >
                    CCBill
                </a>
                . You will get an affiliate ID to&nbsp;login to BCASH4YOU. By
                clicking 'Retrieve data' button, you agree to&nbsp;our{' '}
                <Link
                    href="/legal#terms"
                    className="underline"
                    title="Terms and Conditions"
                >
                    Terms
                </Link>{' '}
                and{' '}
                <Link
                    href="/legal#privacy"
                    title="Privacy Policy"
                    className="underline"
                >
                    Privacy
                </Link>{' '}
                and to&nbsp;receive our emails&nbsp;&amp;&nbsp;updates.
            </p>
            <div className="spacer spacer-80">&nbsp;</div>
            <img
                src={require('../images/trio-001.jpg')}
                style={{ width: '100%', height: 'auto' }}
                alt="W4B"
            />

            <Footer />
        </Layout>
    );
};

export default LegalPage;
